import React from 'react'
import { ToastContainer, Flip } from 'react-toastify'
import { Provider } from 'react-redux'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import theme from '../../src/theme'
import { setupStore } from '../../src/store'
import 'react-toastify/dist/ReactToastify.css'

const store = setupStore()

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <title>Manage - Tri Tag Rugby</title>
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
          rel="stylesheet"
        />
        <script src="https://cs71lqy4q2vf.statuspage.io/embed/script.js"></script>
      </Helmet>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <ToastContainer
          autoClose={3000}
          transition={Flip}
          className="toast-container"
          theme="colored"
          toastClassName="dark-toast"
          position="top-right"
        />
        <CssBaseline />
        <Provider store={store}>{props.children}</Provider>
      </ThemeProvider>
    </React.Fragment>
  )
}

TopLayout.propTypes = {
  children: PropTypes.node,
}
