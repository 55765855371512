import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import Cookie from 'js-cookie'

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.API,
    prepareHeaders: headers => {
      const token = Cookie.get('token')

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
        headers.set('MahanginAko', token)
      }

      return headers
    },
  }),
  tagTypes: [
    'associations',
    'association',
    'association-stripe-links',
    'venues',
    'venue',
    'seasons',
    'season',
    'divisions',
    'division',
    'teams',
    'team',
    'sub-masters',
    'sub-master',
    'incidents',
    'incident',
    'competitions',
    'competition',
    'draw-builders',
    'draw-builder',
    'ladders',
    'ladder',
    'ladder-settings',
    'ladder-setting',
    'participants',
    'participant',
    'matches',
    'roles',
    'match',
    'dashboards',
    'reporting-uniform',
  ],
  endpoints: () => ({}),
})
