import { createTheme } from '@mui/material/styles'

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#008174',
    },
    secondary: {
      main: '#f44336',
    },
  },
  typography: {
    fontFamily: 'Montserrat',
  },
})

export default theme
